 // Import the functions you need from the SDKs you need
 import { initializeApp } from "firebase/app";
 import { getFirestore } from "firebase/firestore";
 // TODO: Add SDKs for Firebase products that you want to use
 // https://firebase.google.com/docs/web/setup#available-libraries
 // Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAJ18IclJZUPuVZYQW2LTOYH9l7xdxxiDY",
  authDomain: "wazzascore.firebaseapp.com",
  projectId: "wazzascore",
  storageBucket: "wazzascore.appspot.com",
  messagingSenderId: "73683965606",
  appId: "1:73683965606:web:eed8323d289ad7d80c0667",
  measurementId: "G-GYRE4WP58Y"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);
