// AdminPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import Button from '@mui/material/Button';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

//firebase
import { collection, getDocs } from "firebase/firestore";
import { db } from './firebase';
import { useState, useEffect } from 'react';


//images
import logoImage from './images/WazzaScore_Logo2.png'; // Import the logo image

//converts Firebase date to JS date
function convertDate(firebaseObject) {
    if (!firebaseObject) return null;

    for (const [key, value] of Object.entries(firebaseObject)) {

        // covert items inside array
        if (value && Array.isArray(value))
            firebaseObject[key] = value.map(item => convertDate(item));

        // convert inner objects
        if (value && typeof value === 'object') {
            firebaseObject[key] = convertDate(value);
        }

        // convert simple properties
        if (value && value.hasOwnProperty('seconds'))
            firebaseObject[key] = value.toDate();
    }
    return firebaseObject;
}

function AdminPage() {
    const navigate = useNavigate();
    const { logout } = useUser();
    const { username } = useUser();

    //firebase
    const [reports, setReports] = useState([])
    const [reviewedReports, setReviewedReports] = useState([])
    const fetchReview = async () => {

        await getDocs(collection(db, "Report"))
            .then((querySnapshot) => {
                const newData = querySnapshot.docs
                    .map((doc) => ({ ...doc.data(), id: doc.id }));
                convertDate(newData);
                setReports(newData);
            })
    }
    const fetchCompletedReviews = async () => {
        await getDocs(collection(db, "ReviewedReports"))
            .then((querySnapshot) => {
                const newData = querySnapshot.docs
                    .map((doc) => ({ ...doc.data(), id: doc.id }));
                convertDate(newData);
                setReviewedReports(newData);
            })

    }

    useEffect(() => {
        fetchReview();
        fetchCompletedReviews();
    }, [])

    const handleLogout = () => {
        logout();
        navigate('/login');
    };
    const deleteReport = () => {
        //todo
    };
    const deleteContribution = () => {
        //todo
    };

    const columns = [
        {
            field: 'ReportType',
            headerName: 'Report Type',
            flex: 1,
            editable: false,
        },
        {
            field: 'ReportedDate',
            headerName: 'Reported Date',
            flex: 1,
            editable: false,
        },
        {
            field: 'FixtureDate',
            headerName: 'Fixture Date',
            flex: 1,
            editable: false,
        },
        {
            field: 'ReportedFixture',
            headerName: 'Reported Fixture',
            flex: 1,
            editable: false,
        },
        {
            field: 'DecidedFixture',
            headerName: 'Decided Fixture',
            flex: 1,
            editable: false,
        },
        {
            field: 'ReporterLevel',
            headerName: 'Reporter level',
            flex: 1,
            editable: false,
        },
        {
            field: 'ContributorLevel',
            headerName: 'Contributor level',
            flex: 1,
            editable: false,
        },
        {
            field: "DeleteReport",
            headerName: "Report Action",
            sortable: false,
            flex: 1,
            renderCell: ({ row }) =>
                <Button onClick={() => deleteReport(row)} variant="contained" color="error">
                    Invalid Report
                </Button>,
        },
        {
            field: "DeleteContribution",
            headerName: "Contribution Action",
            sortable: false,
            flex: 1,
            renderCell: ({ row }) =>
                <Button onClick={() => deleteContribution(row)} variant="contained" color="error">
                    Invalid Contribution
                </Button>,
        },
    ];

    const columnsCompleted = [
        {
            field: 'ReportType',
            headerName: 'Report Type',
            flex: 1,
            editable: false,
        },
        {
            field: 'Outcome',
            headerName: 'Outcome',
            flex: 1,
            editable: false,
        },
        {
            field: 'Reviewer',
            headerName: 'Reviewer',
            flex: 1,
            editable: false,
        },
        {
            field: 'ReportedDate',
            headerName: 'Reported Date',
            flex: 1,
            editable: false,
        },
        {
            field: 'FixtureDate',
            headerName: 'Fixture Date',
            flex: 1,
            editable: false,
        },
        {
            field: 'ReportedFixture',
            headerName: 'Reported Fixture',
            flex: 1,
            editable: false,
        },
        {
            field: 'DecidedFixture',
            headerName: 'Decided Fixture',
            flex: 1,
            editable: false,
        },
        {
            field: 'ReporterLevel',
            headerName: 'Reporter level',
            flex: 1,
            editable: false,
        },
        {
            field: 'ContributorLevel',
            headerName: 'Contributor level',
            flex: 1,
            editable: false,
        },
    ];



    return (
        <div className="AdminPage">
            <header className="AdminHeader">
                <div className="smallLogo">
                    <img src={logoImage} alt="WazzaScore Logo" className="AdminLogo" />
                </div>
                <div className="LogoutButtonContainer">
                    <Button onClick={handleLogout} variant="contained" color="error">Logout</Button>
                </div>
            </header>


            <h1>Wazzaaaaa, {username}!</h1>

            <Box className="ReportsContainer">
                <h2>Reported items for review</h2>
                <DataGrid
                    className="userReportsGrid"
                    rows={reports}
                    columns={columns}
                />

            </Box>

            <Box className="ReportsContainer">
                <h2>Completed reviews</h2>
                <DataGrid
                    className="completedReviewsGrid"
                    rows={reviewedReports}
                    columns={columnsCompleted}
                />

            </Box>

        </div>
    );
}

export default AdminPage;
