import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import logoImage from './images/WazzaScore_Logo2.png'; // Import the logo image

import Button from '@mui/material/Button';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { setUserName, login } = useUser();
    const navigate = useNavigate();

    const handleLogin = () => {
        // Perform authentication logic here
        // Assuming login function updates isLoggedIn state upon successful login
        //TODO: add google firebase database to store user data
        if (password === 'W@zza5c0re') {
            // Login
            login();
            // Save username to context
            setUserName(username);

            // Navigate to the desired page after successful login
            navigate('/noukakjypella');
        } else {
            alert('Invalid username or password');
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className='Loginpage'>
            <img src={logoImage} alt="WazzaScore Logo" className="LoginLogo" ></img>
            <h1>Login Page</h1>
            <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <label>
                <input
                    type="checkbox"
                    checked={showPassword}
                    onChange={toggleShowPassword}
                />
                Show Password
            </label>

            <Button variant="contained" color="success" onClick={handleLogin} >Login</Button>
        </div>
    );
}

export default Login;
