import React, { useState, useEffect } from 'react';
import './App.css';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Stack from "@mui/material/Stack";
import Button from '@mui/material/Button';

//images
import logoImage from './images/WazzaScore_Logo2.png'; // Import the logo image
import googlePlayBadge from './images/google-play-badge.png'; // Import Google Play badge
import appleBadge from './images/Apple badge.png'; // Import App Store badge
import Rankings from './images/Ranking.png';
import Legacy from './images/Legacy.jpg';
import Scores from './images/Score.png';
import Fixtures from './images/Fixtures.png';
import worldmap from './images/worldmap.png';
import DownloadImage from './images/Contribute screen.png';
import AboutUsImage from './images/AboutField.jpg';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';




function LandingPage() {
  const [showTagline, setShowTagline] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [splashScreenHeight, setsplashScreenHeight] = useState('100vh');

  //useeffect that sets the showTagline state to true after 1 second
  useEffect(() => {
    const taglineTimer = setTimeout(() => {
      setShowTagline(true);
    }, 1000); // Delay of 1 second before showing tagline
    return () => clearTimeout(taglineTimer);
  }, []);

  useEffect(() => {
    let headerTimer = null;
    if (showTagline) {
      headerTimer = setTimeout(() => {
        setsplashScreenHeight('0');
        setShowTagline(false);
      }, 1800); // Adding a 1-second delay after Logo appears
    }
    return () => clearTimeout(headerTimer);
  }, [showTagline]);

  useEffect(() => {
    // Simulating image loading with a setTimeout
    const imageTimer = setTimeout(() => {
      setImageLoaded(true);
    }, 2100); // Adjust this delay as needed

    // Clear the timer on component unmount
    return () => clearTimeout(imageTimer);
  }, []);

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    
    <div className="App">


      <header className="Header" >
        <div className="smallLogo" style={{ opacity: imageLoaded ? 1 : 0 }} /*Set logo opacity based on showTagline state */>
          <img src={logoImage} alt="WazzaScore Logo" className={imageLoaded ? 'logo loaded' : 'logo'} />
        </div>
      </header>
      <div className="SplashScreen" style={{ height: splashScreenHeight }}>
        <img src={logoImage} alt="WazzaScore Logo" className="SplashLogo" ></img>
        <div className={`Tagline ${showTagline ? 'ShowTagline' : ''}`}>
          <h1>Be part of the legacy.</h1>
        </div>
      </div>


      <Grid container spacing={0} className="DownloadSection" >
        <Grid xs={12} md={6} lg={5}>
          <div className="DownloadImageContainer">
            <img src={DownloadImage} alt="DownloadImage" className="DownloadImage" />
          </div>
        </Grid>
        <Grid xs={12} md={6} lg={7}>
          <div className="DownloadButtonsContainer">
            <div className="DownloadButtons">
              <h1 className="DownloadHeader" >Your team is on the app, trust us.</h1>
              <div className="GooglePlayButtonDiv">
                {/* Replace buttons with actual links * 
                <a href="#" className="GooglePlayButton">
                  <img src={googlePlayBadge} alt="Google Play Badge" className="Badge" />
                </a>
              </div>
              <div className="AppStoreDiv">
                <a href="#" className="AppStoreButton">
                  <img src={appleBadge} alt="App Store Badge" className="Badge" />
                </a>
                */}
              </div>
              
            </div>
          </div>
        </Grid>
      </Grid>

      <Box className="FeatureSection" sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value} setValue={1} initialSelectedIndex={1}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              className="TabList"
              onChange={handleChange}
              centered
              variant="fullWidth"
              initialSelectedIndex={1}
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#1cac53',
                },
              }}
              sx={{
                '& .MuiTab-root': {
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  textTransform: 'none',
                  backgroundColor: '#131314',
                  borderRight: '1px solid grey',
                  '&.Mui-selected': {
                    color: '#1cac53',
                    indicator: {
                      backgroundColor: 'wazzaGroen',
                    },
                  },

                },

              }}
            >
              <Tab label="Rankings" value="1" />
              <Tab label="Scores" value="2" />
              <Tab label="Fixtures" value="3" />
              <Tab label="Legacy" value="4" />
            </TabList>
          </Box>
          <TabPanel value="1" >
            <Grid container spacing={0} className="Rankings" >
              <Grid xs={6} md={6} lg={6}>
                <div className="FeatureImageContainer">
                  <img src={Rankings} alt="RankingsImage" className="RankingsImage" />
                </div>
              </Grid>
              <Grid xs={6} md={6} lg={6}>
                <div className="FeatureTextContainer">
                  <h1 className="FeatureHeader" >Rankings</h1>
                  <p className="FeatureText">
                    Watch your team shine as they compete against others, transparently ranked by our unbiased algorithm.
                    <br></br>
                    <br></br>
                    Get weekly updates, keeping you in the loop as your team climbs the local and national ranks.
                    <br></br>
                    <br></br>
                    Experience the excitement of sports like never before!
                  </p>
                </div>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <Grid container spacing={0} className="Scores" >
              <Grid xs={6} md={6} lg={6}>
                <div className="FeatureImageContainer">
                  <img src={Scores} alt="ScoresImage" className="ScoresImage" />
                </div>
              </Grid>
              <Grid xs={6} md={6} lg={6}>
                <div className="FeatureTextContainer">
                  <h1 className="FeatureHeader" >Scores</h1>
                  <p className="FeatureText">
                    Stay up-to-date with live fixture results for your own team and those you follow closely.
                    <br></br>
                    <br></br>
                    Dive into historical data to relive past victories and analyze team performance.
                    <br></br>
                    <br></br>
                    With WazzaScore, you're always in the game.
                  </p>
                </div>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="3">
            <Grid container spacing={0} className="Fixtures" >
              <Grid xs={6} md={6} lg={6}>
                <div className="FeatureImageContainer">
                  <img src={Fixtures} alt="FixturesImage" className="FixturesImage" />
                </div>
              </Grid>
              <Grid xs={6} md={6} lg={6}>
                <div className="FeatureTextContainer">
                  <h1 className="FeatureHeader" >Fixtures</h1>
                  <p className="FeatureText">
                    Easily view upcoming matches for your own team and those you follow closely,
                    ensuring you stay informed and never miss a game.
                    <br></br>
                    <br></br>
                    Set up fixtures for your team effortlessly,
                    ensuring all your supporters and players stay informed.
                    <br></br>
                    <br></br>Plus, delve into detailed statistics for valuable
                    insights into upcoming fixtures.
                  </p>
                </div>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="4">
            <Grid container spacing={0} className="Legacy" >
              <Grid xs={6} md={6} lg={6}>
                <div className="LegacyImageContainer">
                  <img src={Legacy} alt="LegacyImage" className="LegacyImage"
                  />
                </div>
              </Grid>
              <Grid xs={6} md={6} lg={6}>
                <div className="FeatureTextContainer">
                  <h1 className="FeatureHeader" >Legacy</h1>
                  <p className="FeatureText">
                    Be part of your team's legacy.
                    <br></br>
                    <br></br>
                    Join a vibrant community of supporters, alumni and players, where you can contribute to fixtures and scores to elevate your team's presence.
                    <br></br>
                    <br></br>
                    Showcase your team's achievements and share rankings, results, and upcoming fixtures with ease. Plus, delve into detailed statistics for valuable
                    insights into past and upcoming fixtures.
                    <br></br>
                    <br></br>
                    Your team, your legacy, all in one place.
                  </p>
                </div>
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Box>

      <Grid container spacing={0} className="CoverageSection" >

        <Grid xs={12} md={6} lg={7}>
          <div className="CoverageTextContainer">

            <p className="CoverageText">
              While we're currently operating exclusively in South Africa,
              rest assured, we're actively working to bring our innovative
              features and services to your doorstep.
              <br></br>
              <br></br>
              Stay tuned for updates as we strive to enhance your sports experience, right here in
              your own backyard.</p>
          </div>
        </Grid>
        <Grid xs={12} md={6} lg={5}>
          <img src={worldmap} alt="world map" className="worldmap" />
        </Grid>
      </Grid>

      <Grid container spacing={0} className="AboutSection" >
        <Grid xs={12} md={5} lg={5}>
          <div className="AboutImageContainer">
            <img src={AboutUsImage} alt="sport field" className="aboutusimage" />
          </div>

        </Grid>
        <Grid xs={12} md={7} lg={7}>
          <div className="AboutTextContainer">
            <h1 className="AboutHeader" >About Us</h1>
            <Stack direction="row" alignItems="center" spacing={2} className="AboutText">
              <FacebookIcon sx={{ fontSize: 42, cursor:"pointer" }} onClick={() => window.open("https://www.facebook.com/profile.php?id=61557281487889&mibextid=LQQJ4d")}/>
              <XIcon sx={{ fontSize: 42, cursor:"pointer" }} onClick={() => window.open("https://x.com/wazzascore?s=11")}/>
              <InstagramIcon sx={{ fontSize: 42, cursor:"pointer" }} onClick={() => window.open("https://www.instagram.com/wazzascore?igsh=NGRreWNibnVzMjQx&utm_source=qr")}/>
            </Stack>
            <p className="AboutText">

              <br></br>
              At Wazzascore, we are passionate about revolutionizing the way
              you experience sports.
              <br></br>
              <br></br>
              Our mission is to provide you with a seamless
              platform that brings you closer to the action,
              whether you're cheering from the sidelines or
              diving into the heart of the game.
              <br></br>
              <br></br>
              For inquiries, feedback, or partnerships,
              please feel free to reach out to us:
              <br></br>
              <br></br>
              <ul>
                <li><strong>Email:</strong> <a href="mailto:info@wazzascore.com">info@wazzascore.com</a></li>
                <li><strong>WhatsApp:</strong> +27 81 540 5428</li>
                
              </ul>
              <Button variant="contained" color="success" href="https://wa.me/27815405428">Click to chat</Button>
              <br></br>
            </p>

          </div>
        </Grid>
      </Grid>


      <footer className="Footer">
        <p>&copy; 2024 WazzaScore</p>
      </footer>
    </div>
  );
}

export default LandingPage;
